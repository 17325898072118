<template>
  <div class="wap-recharge user">
    <headerBar :title="$t('dai-kuan-shen-qing-0')" @back="back"></headerBar>
    <div class="recharge-form">
      <div class="form-item">
        <div class="label">{{ $t('shen-qing-ren') }}</div>
        <div class="input">
          <Field
            v-model="form.username"
            :placeholder="$t('zhen-shi-xing-ming')"
          >
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('dai-kuan-qi-xian') }}</div>
        <div class="input">
          <Field
            v-model="form.timeName"
            :placeholder="$t('qing-xuan-ze-dai-kuan-qi-xian')"
            readonly
            @click="changeTime"
          >
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('shen-qing-jin-e') }}</div>
        <div class="input">
          <Field
            v-model="form.number"
            :placeholder="$t('4999-500000-0')"
          ></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('dai-kuan-li-shuai') }}</div>
        <div class="input">
          <Field v-model="form.percent" readonly></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('fang-kuan-fang-shi') }}</div>
        <div class="input">
          <Field v-model="form.typeName" readonly></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('guo-ji') }}</div>
        <div class="input">
          <Field
            v-model="form.country"
            :placeholder="$t('qing-shu-ru-guo-ji-0')"
          ></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zheng-jian-hao-ma-hu-zhao-hao-ma') }}</div>
        <div class="input">
          <Field
            v-model="form.cardNo"
            :placeholder="$t('qing-shu-ru-zheng-jian-hao-ma-hu-zhao-hao-ma-0')"
          ></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('zheng-jian-zhao-shang-chuan-hu-zhao') }}</div>
        <div class="flex-center-between">
          <div class="upload flex-column-center">
            <el-upload
              style="width: 80px; height: 80px"
              class="avatar-uploader"
              :show-file-list="false"
              :action="uploadUrl"
              name="file"
              :headers="headers"
              :on-success="(file) => uploadSuccess(file, 'img1')"
              :before-upload="beforeUpload"
            >
              <img
                v-if="form.img1"
                :src="getBaseUrl(form.img1)"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-camera-solid"
                style="font-size: 30px; color: #aaa"
              ></i>
            </el-upload>
            <span>{{ $t('zheng-jian-zheng-mian') }}</span>
          </div>
          <div class="upload flex-column-center">
            <el-upload
              style="width: 80px; height: 80px"
              class="avatar-uploader"
              :show-file-list="false"
              :action="uploadUrl"
              name="file"
              :headers="headers"
              :on-success="(file) => uploadSuccess(file, 'img2')"
              :before-upload="beforeUpload"
            >
              <img
                v-if="form.img2"
                :src="getBaseUrl(form.img2)"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-camera-solid"
                style="font-size: 30px; color: #aaa"
              ></i>
            </el-upload>
            <span>{{ $t('zheng-jian-fan-mian') }}</span>
          </div>
          <div class="upload flex-column-center">
            <el-upload
              style="width: 80px; height: 80px"
              class="avatar-uploader"
              :show-file-list="false"
              :action="uploadUrl"
              name="file"
              :headers="headers"
              :on-success="(file) => uploadSuccess(file, 'img3')"
              :before-upload="beforeUpload"
            >
              <img
                v-if="form.img3"
                :src="getBaseUrl(form.img3)"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-camera-solid"
                style="font-size: 30px; color: #aaa"
              ></i>
            </el-upload>
            <span>{{ $t('shou-chi-zheng-jian-zhao') }}</span>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('shi-li') }}</div>
        <div class="flex-center-between">
          <div class="demo">
            <img :src="demoUrl1" alt="" />
          </div>
          <div class="demo">
            <img :src="demoUrl2" alt="" />
          </div>
          <div class="demo">
            <img :src="demoUrl3" alt="" />
          </div>
        </div>
      </div>

      <div class="form-item">
        <Button class="submit-btn" @click="submit">{{ $t('ti-jiao') }}</Button>
      </div>
    </div>
    <Popup position="bottom" v-model="showPopup">
      <Picker
        :columns="timeList"
        value-key="label"
        show-toolbar
        @cancel="cancel"
        @confirm="chooseTime"
      ></Picker>
    </Popup>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'
import headerBar from '@/components/header'
import { Field, Button, Popup, Picker } from 'vant'
import { getToken } from '@/utils/auth'
import { getRechargeInfo, addRecharge } from '@/api/user'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    headerBar,
    Field,
    Button,
    Popup,
    Picker
  },
  data() {
    return {
      demoUrl1: require('@/assets/imgs/upload-demo1.png'),
      demoUrl2: require('@/assets/imgs/upload-demo2.png'),
      demoUrl3: require('@/assets/imgs/upload-demo3.png'),
      form: {
        timeName: '',
        time: '',
        username: '',
        percent: '0.3%',
        typeName: this.$t('zhang-hu-yu-e'),
        img1: '',
        img2: '',
        img3: ''
      },
      headers: {},
      pageType: '',
      showPopup: false,
      timeList: [
        {
          value: 1,
          label: this.$t('1-tian')
        },
        {
          value: 10,
          label: this.$t('10-tian')
        },
        {
          value: 20,
          label: this.$t('20-tian')
        },
        {
          value: 30,
          label: this.$t('30-tian')
        },
        {
          value: 60,
          label: this.$t('60-tian')
        },
        {
          value: 90,
          label: this.$t('90-tian')
        },
        {
          value: 120,
          label: this.$t('120-tian')
        }
      ]
    }
  },
  computed: {
    imgType() {
      return this.$store.state.imgType
    },

    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.headers = {
      token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {},
    back() {
      this.$router.go(-1)
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    uploadSuccess(file, key) {
      this.form[key] = file.data.FileName
    },
    changeTime() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    chooseTime(e) {
      this.form.time = e.value
      this.form.timeName = e.label
      this.showPopup = false
    },
    submit() {}
  }
}
</script>